<script lang="ts" setup>
import BlobMaterial from "@/components/three/materials/BlobMaterial";
import {usePreferredReducedMotion} from "@vueuse/core";

const m = await BlobMaterial();
const {onLoop} = useRenderLoop();
const preference = usePreferredReducedMotion();

onLoop(({elapsed}) => {
  if (!m.uniforms) return;
  const reduced = preference.value === "reduce";
  m.uniforms.time.value = elapsed * (reduced ? 0.01 : 0.1);
});
</script>

<template>
  <TresMesh :material="m">
    <TresSphereGeometry :args="[2, 64, 64]"></TresSphereGeometry>
  </TresMesh>
</template>
