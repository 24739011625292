<script setup lang="ts">
import {setRendering} from "~/components/three/utils/setRendering";
import * as THREE from "three";

const props = defineProps<{
  toolMode?: boolean;
  clearColor?: {r: number; g: number; b: number; a: number};
}>();

const tres = useTresContext();
const {onBeforeLoop} = useRenderLoop();
const renderer = tres.renderer.value;
setRendering(renderer, props.toolMode);

const clearColorX = new THREE.Color();
onBeforeLoop(() => {
  const v = props.clearColor;
  if (!v) return;
  const c = clearColorX.setRGB(v.r, v.g, v.b);
  renderer.setClearColor(c, v.a);
});

defineExpose({glContext: renderer.getContext()});
</script>
<template></template>
