// @ts-nocheck renderer types aren't entirely completely it would seem
import {ACESFilmicToneMapping, SRGBColorSpace, WebGLRenderer} from "three";

export const setRendering = (renderer: WebGLRenderer, toolMode = false) => {
  renderer.shadows = false;
  renderer.physicallyCorrectLights = true;
  renderer.antialias = true;
  renderer.outputColorSpace = SRGBColorSpace;
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 3;
  renderer.shadowMap.enabled = false;
  if (toolMode) {
    renderer.setPixelRatio(2); // Breaks larger image resolutions.
    // TODO:s
    // - Make sure we can export based on pixels that are larger than what the device supports in one go (MAX_RENDERBUFFER_SIZE, MAX_VIEWPORT_DIMS)
    // - On export do scissors of the maximum sections and export the total required
    // - Make sure the animation is paused on generation to prevent jumps in between the slices.
    // https://chatgpt.com/share/67c188c1-550c-8008-8d16-577db90c48b9
    renderer.alpha = true;
    // renderer.setClearColor(0xff0000, 1);
  }
};
