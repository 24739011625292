import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";
export default async (
  radialSegments: number,
  lengthSegments: number,
  color?: THREE.ColorRepresentation,
) => {
  // @ts-expect-error Import does not understand glsl
  const _vertexShader = await import("./vertexShader.glsl");
  const vertexShader = _vertexShader.default;

  const defines = {
    PI: Math.PI,
    ROBUST: false,
    ROBUST_NORMAL: false,
    MAX_NUMBER: Number.MAX_VALUE,
  };
  const uniforms = {
    thickness: {value: 0.001},
    time: {value: 0.0},
    colorX: {value: new THREE.Color("#fff")},
    animateRadius: {value: 0.0},
    animateStrength: {value: 0.0},
    index: {value: 0.0},
    radialSegments: {value: radialSegments},
    lengthSegments: {value: lengthSegments},
  };

  return new CustomShaderMaterial({
    baseMaterial: THREE.MeshStandardMaterial,
    metalness: 1,
    roughness: 0.1,
    vertexShader,
    color: color ?? 0xffffff,
    uniforms,
    defines,
  });
};
