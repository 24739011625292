import * as THREE from "three";

export default (numSides = 8, subdivisions = 50, openEnded = false) => {
  // Create base geometry
  const baseGeometry = new THREE.CylinderGeometry(
    1,
    1,
    1,
    numSides,
    subdivisions,
    openEnded,
  );

  // Rotate the cylinder so the X-axis becomes the length axis
  baseGeometry.rotateZ(Math.PI / 2);

  // Convert geometry to non-indexed (unroll vertices)
  const unindexedGeometry = baseGeometry.toNonIndexed();

  // Extract attributes
  const posAttr = unindexedGeometry.attributes.position;
  const uvAttr = unindexedGeometry.attributes.uv;

  const arcLengths = [];
  const angles = [];
  const uvs = [];
  const positions = [];

  const tmpVec = new THREE.Vector2();

  // Iterate over all vertices
  for (let i = 0; i < posAttr.count; i++) {
    const x = posAttr.getX(i); // Arc length (x-coordinate along the cylinder)
    const y = posAttr.getY(i);
    const z = posAttr.getZ(i);

    // Push position data (required for rendering)
    positions.push(x, y, z);

    // Calculate the radial angle (angle in the Y-Z plane)
    tmpVec.set(y, z).normalize();
    const angle = Math.atan2(tmpVec.y, tmpVec.x);

    // Push arc length and angle
    arcLengths.push(x);
    angles.push(angle);

    // Push UVs
    uvs.push(uvAttr.getX(i), uvAttr.getY(i));
  }

  // Create a new BufferGeometry
  const geometry = new THREE.BufferGeometry();

  // Set position attribute (vec3)
  geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(new Float32Array(positions), 3),
  );

  // Add custom attributes
  geometry.setAttribute(
    "arcLength",
    new THREE.BufferAttribute(new Float32Array(arcLengths), 1),
  );
  geometry.setAttribute(
    "angle",
    new THREE.BufferAttribute(new Float32Array(angles), 1),
  );
  geometry.setAttribute(
    "uv",
    new THREE.BufferAttribute(new Float32Array(uvs), 2),
  );

  // Dispose of old geometry
  unindexedGeometry.dispose();
  baseGeometry.dispose();

  return geometry;
};
