import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

export default async (color?: THREE.ColorRepresentation) => {
  // @ts-expect-error Import does not understand glsl
  const _vertexShader = await import("./blobVertex.glsl");
  const vertexShader = _vertexShader.default;

  const uniforms = {
    time: {value: 0.0},
    offset: {
      value: Math.random() * 1000,
    },
  };

  return new CustomShaderMaterial({
    baseMaterial: THREE.MeshPhongMaterial,
    vertexShader,
    uniforms,
  });
};
