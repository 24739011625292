<script setup lang="ts">
import createGeometry from "@/utils/three/createGeometry";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";
import ParametricCustomShaderMaterial from "@/components/three/materials/ParametricCustomShaderMaterial";
import * as THREE from "three";
import {useRenderLoop} from "@tresjs/core";
import {usePreferredReducedMotion} from "@vueuse/core";

const props = defineProps<{
  hq?: boolean;
}>();

const totalMeshes = 24;
const radialSegments = props.hq ? 3.0 : 3.0;
const lengthSegments = props.hq ? 720.0 : 360.0;

const geometry = createGeometry(radialSegments, lengthSegments);

const material: CustomShaderMaterial = await ParametricCustomShaderMaterial(
  radialSegments,
  lengthSegments,
);

// 1. Create an InstancedMesh with the shared geometry and material.
const count = totalMeshes;
const instancedMesh = new THREE.InstancedMesh(geometry, material, count);

// 2. Prepare per-instance data arrays.
const indexArray = new Float32Array(count);

// A dummy object to help set instance transforms.
const dummy = new THREE.Object3D();

for (let i = 0; i < count; i++) {
  // Compute your custom value for each instance.
  const t = count <= 1 ? 0 : i / (count - 1);
  indexArray[i] = t + 1.0;
  // Set up instance transformation.
  dummy.position.set(0, 0, 0); // Adjust position as needed.
  dummy.scale.setScalar(5);
  dummy.updateMatrix();

  // Set the instance matrix.
  instancedMesh.setMatrixAt(i, dummy.matrix);
}

// 3. Attach instanced attributes to the geometry.
instancedMesh.geometry.setAttribute(
  "index",
  new THREE.InstancedBufferAttribute(indexArray, 1),
);

instancedMesh.instanceMatrix.needsUpdate = true;

const {onLoop} = useRenderLoop();
const preference = usePreferredReducedMotion();
const seed = Math.random() * 2;
onLoop(({elapsed}) => {
  const reduced = preference.value === "reduce";
  material.uniforms.time.value = seed + elapsed * (reduced ? 0.02 : 0.2);
});
</script>

<template>
  <primitive :object="instancedMesh" />
</template>
