<script lang="ts" setup>
import * as THREE from "three";
import {useRenderLoop, useTexture} from "@tresjs/core";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";
import {MeshPhysicalMaterial} from "three";

const _vertexShader = await import("./materials/vxShader.glsl");
const vertexShader = _vertexShader.default;

const w = 10;
const h = 20;
const geometry = new THREE.PlaneGeometry(w, h, 120, 120);
geometry.rotateX(Math.PI / 2);

const texture = await useTexture(["/texture.png"]);
texture.repeat.set(10, 1);
texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
const perlin = await useTexture(["/perlin.png"]);
perlin.wrapS = THREE.RepeatWrapping; // Repeat horizontally
perlin.wrapT = THREE.RepeatWrapping; // Repeat vertically
perlin.repeat.set(1, 0.5);
const material = new CustomShaderMaterial({
  baseMaterial: MeshPhysicalMaterial,
  roughness: 0.5,
  color: 0xffffff,
  transparent: true,
  side: THREE.DoubleSide,
  vertexShader: vertexShader,
  bumpMap: perlin,
  bumpScale: 4,
  // alphaToCoverage: true,
  // sheen: 2.0, // Adds soft highlights (especially with an envMap)
  // sheenColor: new THREE.Color('#fff'),
  // sheenRoughness: 0.5, // Controls how soft the sheen looks
  uniforms: {
    uTime: {
      value: 0,
    },
    uWidth: {
      value: 8,
    },
    uHeight: {
      value: h,
    },
  },
});
const mesh = new THREE.Mesh(geometry, material);
mesh.rotateY(Math.PI / 4);
mesh.frustumCulled = false;

const {onLoop} = useRenderLoop();
const seed = Math.random() * 2;
onLoop(({elapsed}) => {
  material.uniforms.uTime.value = seed + elapsed * 0.2;
});
</script>

<template>
  <TresGroup>
    <primitive :object="mesh" />
  </TresGroup>
</template>

<style lang="scss" scoped></style>
